import http from './http-common'

export async function getFeeds (filters) {
  return await http.get(`/staff/news/jewelry?sort=createdDate,desc&title=${filters.name}&start=${filters.start}&end=${filters.end}&size=${filters.size}&page=${filters.page}`)
}

export async function getFeed (id) {
  return await http.get(`/staff/news/jewelry/${id}`)
}

export async function createFeed (data) {
  return await http({
    method: 'POST',
    url: '/staff/news/jewelry',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data;boundary = ' + new Date().getTime()
    }
  })
}

export async function updateFeed (id, data) {
  return await http({
    method: 'PUT',
    url: `/staff/news/jewelry/${id}`,
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data;boundary = ' + new Date().getTime()
    }
  })
}

export async function deactivateFeed (id) {
  return await http.put(`/staff/news/jewelry/${id}/deactivate`)
}

export async function activateFeed (id) {
  return await http.put(`/staff/news/jewelry/${id}/activate`)
}

export async function deleteFeed (id) {
  return await http.delete(`/staff/news/jewelry/${id}`)
}

export async function deleteNewImage (newId, imageUrl) {
  return await http.put(`/staff/news/delete-news-image/${newId}?imageUrl=${imageUrl}`)
}
