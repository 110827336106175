<template>
  <b-container fluid>
    <b-row class="mb-4">
      <b-col cols="12" xl="4" md="6" class="mb-2">
        <div class="input-group">
          <span class="input-group-text input-group-text--addon-left">
            <b-icon icon="search"></b-icon>
          </span>
          <input
            v-model="filters.name"
            v-on:keyup="search"
            type="text"
            class="form-control form-control--addon-left form-control--h46px pl-48px mw-324px rounded-10px"
            placeholder="搜索珠寶資訊標題"
          >
        </div>
      </b-col>
      <b-col cols="12" xl="2" md="6" class="mb-2">
        <b-button
          @click="showCreateModal"
          class="btn btn-secondary btn--h46px justify-content-center w-100"
        >
          新創建
        </b-button>
      </b-col>
      <b-col cols="12" xl="3" md="6" class="mb-2">
        <b-form-datepicker
          id="datepicker-start"
          v-model="filters.start"
          @input="getAll"
          class="b-form--datepicker form-control--h46px"
          today-button
          reset-button
          close-button
          locale="zh"
          placeholder="2021 年 11 月 10 日"
        ></b-form-datepicker>
      </b-col>
      <b-col cols="12" xl="3" md="6" class="mb-2">
        <b-form-datepicker
          id="datepicker-end"
          v-model="filters.end"
          @input="getAll"
          class="b-form--datepicker form-control--h46px"
          today-button
          reset-button
          close-button
          locale="zh"
          placeholder="2021 年 11 月 10 日"
        ></b-form-datepicker>
      </b-col>
    </b-row>
    <TableData :items="feeds.content" :headers="headers" :isLoading="isLoading">
        <template v-slot:custom-imageUrl="{ item }">
          <img class="rounded-sm" width="110" v-bind:src="item.imageUrl[0]" />
        </template>
        <template v-slot:custom-createDate="{ item }">
          {{ item.createdDate | formatDate }}
        </template>
        <template v-slot:custom-description="{ item }">
          <div class="text-truncate-3" v-html="item.descriptionZhHk"></div>
        </template>
        <template v-slot:custom-descriptionCn="{ item }">
          <div class="text-truncate-3" v-html="item.descriptionZhCn"></div>
        </template>
        <template v-slot:custom-status="{ item }">
          <div v-if="item.active == true" class="text-success">啟用</div>
          <div v-else-if="item.active == false" class="text-danger">停用</div>
        </template>
        <template v-slot:custom-action="{ item }">
          <b-dropdown
            variant="link"
            toggle-class="text-white text-decoration-none"
            no-caret
          >
            <template #button-content>
              <b-icon icon="three-dots" font-scale="1.5" class="text-dark"></b-icon>
            </template>

            <b-dropdown-item @click="showEditModal(item.id)">編輯</b-dropdown-item>
            <b-dropdown-item
              :disabled="item.active == false"
              v-on:click="handleDeactivate(item.id)"
            >
              停用
            </b-dropdown-item>
            <b-dropdown-item
              :disabled="item.active == true"
              v-on:click="handleActivate(item.id)"
            >
              啟用
            </b-dropdown-item>
            <b-dropdown-item v-on:click="handleDelete(item.id)">
              刪除
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </TableData>

    <nav aria-label="Feeds pagination">
      <ul class="pagination">
        <li class="page-item" :class="{ 'disabled active': feeds.first }">
          <a
            v-on:click="handlePaginate(feeds.number - 1)"
            class="page-link"
            href="#"
          >
            以前的
          </a>
        </li>
        <li
          v-for='pageNo in feeds.totalPages'
          :key="pageNo"
          class="page-item"
          v-bind:class="{ 'disabled active': ((pageNo - 1) === feeds.number) }"
        >
          <a
            v-on:click="handlePaginate(pageNo - 1)"
            class="page-link"
            href="#"
          >{{ pageNo }}</a>
        </li>

        <li class="page-item" :class="{ 'disabled active': feeds.last }">
          <a
            v-on:click="handlePaginate(feeds.number + 1)"
            class="page-link"
            href="#"
          >
            下一頁
          </a>
        </li>
      </ul>
    </nav>

    <!-- Create Feed Modal -->
    <b-modal ref="createFeedModal" centered size="lg" no-close-on-backdrop hide-header hide-footer>
      <b-alert show variant="danger" v-if="veeErrors.all().length">
        <ul class="list-unstyled mb-0">
          <li v-for="error in filterVeeErrors(veeErrors.all())" :key="error">{{ error }}</li>
        </ul>
      </b-alert>

      <b-form @submit.stop.prevent="onSubmitCreateFeed" class="p-3 p-lg-5">
        <h5 class="text-brown">珠寶資訊</h5>

        <div class="mb-5">
          <b-row class="mb-3">
            <b-col sm="6" lg="4" class="order-2 order-lg-1">
              <div style="position: relative; background: #ababab; height: 200px; width: 200px; display: flex; align-items: center; justify-content: center; overflow: hidden;">
                <b-carousel
                  class="preview-image d-flex align-items-center justify-content-center text-brown"
                  id="carousel-1"
                  controls
                  indicators
                  :interval="4000"
                  background="#ababab"
                  img-width="200"
                  img-height="200"
                  @sliding-start="updateIndex"
                >
                  <div
                    v-if="!previewImagesList.length"
                    class="d-flex align-items-center justify-content-center"
                  >
                    預覽產品相片
                  </div>
                  <b-carousel-slide
                    v-for="(image, index) in previewImagesList"
                    :key="index"
                  >
                    <template v-slot:img>
                      <div
                        class="d-flex justify-content-center align-items-center"
                        style="height: 100%; width: 100%"
                      >
                        <img
                          :src="image"
                          class="img-fluid"
                          style="object-fit: cover; width: 100%; height: 100%"
                        />
                      </div>
                    </template>
                    <div
                      v-if="!!previewImagesList.length"
                      class="d-flex align-items-center justify-content-center pt-4"
                    >
                      {{ currentIndex + 1 }} /{{ previewImagesList.length }}
                    </div>
                  </b-carousel-slide>
                </b-carousel>
              </div>
            </b-col>
            <b-col sm="6" lg="" class="order-3 order-lg-2">
              <div class="d-flex">
                <b-form-file
                  id="previewImage"
                  class="d-none"
                  ref="fileImage"
                  name="image"
                  accept=".jpg, .jpeg, .png,"
                  v-validate="{
                    required: false,
                    ext: 'jpeg|jpg|png',
                    size: 1024,
                  }"
                  :state="validateState('image')"
                  aria-describedby="input-image-live-feedback"
                  data-vv-as="image"
                  @input="browseImage"
                  multiple
                  :disabled="
                    previewImagesList.length >= 10 && !!previewImagesList
                  "
                ></b-form-file>
                <div class="mb-3">
                  <label
                    :class="{
                      disabledLabel:
                        previewImagesList.length >= 10 && !!previewImagesList,
                    }"
                    for="previewImage"
                    class="bg-white text-brown border border-brown--light rounded-6px py-2 px-4"
                    >上傳產品相片</label
                  >
                </div>
                <div v-if="previewImagesList.length" class="ml-4">
                  <b-button v-b-modal.modal-1>查看全部</b-button>
                  <b-modal size="lg" id="modal-1" title="預覽產品相片">
                    <div class="d-flex flex-wrap">
                      <div
                        class="mx-2 my-4"
                        style="width: 175px; height: 160px"
                        v-for="(image, index) in previewImagesList"
                        :key="index"
                      >
                        <div
                          class="border overflow-hidden rounded"
                          style="width: 100%; height: 100%"
                        >
                          <img
                            class=""
                            :src="image"
                            alt="Preview"
                            style="width: 100%; height: 100%; object-fit: cover"
                          />
                        </div>
                        <img
                          @click="removeImage(index)"
                          style="margin-top: -60px; cursor: pointer"
                          src="../assets/svg/delete.svg"
                          width="32"
                          height="32"
                        />
                      </div>
                    </div>
                    <template v-slot:modal-footer
                      ><b-button block @click="$bvModal.hide('modal-1')"
                        >關閉</b-button
                      ></template
                    >
                  </b-modal>
                </div>
              </div>
              <div>
                <div class="mb-1">只接受 jpg,jpeg,png格式</div>
                <div class="mb-1">建議尺寸 1,200 x 630 px</div>
                <div class="mb-1">最大上傳檔案 1Mb</div>
              </div>
            </b-col>

            <b-col lg="2" class="order-1 order-lg-3 mb-2 mt-3 mt-lg-4">
              <div class="float-lg-right">
                <div class="d-flex align-items-center">
                  <div class="mr-2">啟用</div>
                  <b-form-checkbox
                    name="check-button"
                    class="custom-switch--success"
                    switch
                    v-model="formControl.active"
                    :checked="formControl.active"
                    ></b-form-checkbox>
                </div>
              </div>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col sm="4">
              <label for="titleZhHk" class="text-brown">珠寶資訊標題 <span class="text-danger">*</span></label>
            </b-col>
            <b-col sm="8" lg="6">
              <b-form-input
                id="titleZhHk"
                class="rounded-0"
                name="titleZhHk"
                v-model="formControl.titleZhHk"
                v-validate="{ required: true, max: 100 }"
                :state="validateState('titleZhHk')"
                aria-describedby="input-titleZhHk-live-feedback"
                data-vv-as="titleZhHk"
              ></b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col sm="4">
              <label for="titleZhCn" class="text-brown">珠宝资讯标题 (简体版本)</label>
            </b-col>
            <b-col sm="8" lg="6">
              <b-form-input
                id="titleZhCn"
                class="rounded-0"
                name="titleZhCn"
                v-model="formControl.titleZhCn"
                v-validate="{ required: false, max: 100 }"
                :state="validateState('titleZhCn')"
                aria-describedby="input-titleZhCn-live-feedback"
                data-vv-as="titleZhCn"
              ></b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-3">
          <b-col sm="4">
            <label for="sendNotification" class="text-brown">發送通知</label>
          </b-col>
          <b-col sm="8" lg="6">
            <b-form-checkbox
              name="check-button"
              class="custom-switch--success"
              switch
              v-model="formControl.sendNotification"
              :checked="formControl.sendNotification"
            >
            </b-form-checkbox>
          </b-col>
        </b-row>
          <b-tabs class="tabs--form mb-4">
            <b-tab>
              <template #title>
                <div class="nav-item--text">珠寶資訊描述<span class="text-danger ml-2">*</span></div>
              </template>
              <vue-editor
                id="descriptionZhHk"
                :editorToolbar="descriptionToolbar"
                name="descriptionZhHk"
                v-model="formControl.descriptionZhHk"
                v-validate="{ required: true, max: 2000 }"
                :state="validateState('descriptionZhHk')"
                data-vv-as="descriptionZhHk"
                placeholder="這款閃閃發光的光環鑲托飾有美麗的扇形密釘鑽石，環繞著中央寶石並裝飾著戒環。鑲有鑽石的畫廊使這枚戒指真正壯觀。"
              ></vue-editor>
              <small class="d-block text-danger" id="input-descriptionZhHk-live-feedback" v-if="veeErrors.has('descriptionZhHk')">{{ veeErrors.first('descriptionZhHk') }}</small>
            </b-tab>

            <b-tab>
              <template #title>
                <div class="nav-item--text">珠宝资讯描述 (简体版本)</div>
              </template>
              <vue-editor
                id="descriptionZhCn"
                :editorToolbar="descriptionToolbar"
                name="descriptionZhCn"
                v-model="formControl.descriptionZhCn"
                v-validate="{ required: false, max: 2000 }"
                :state="validateState('descriptionZhCn')"
                data-vv-as="descriptionZhCn"
                placeholder="這款閃閃發光的光環鑲托飾有美麗的扇形密釘鑽石，環繞著中央寶石並裝飾著戒環。鑲有鑽石的畫廊使這枚戒指真正壯觀。"
              ></vue-editor>
              <small class="d-block text-danger" id="input-descriptionZhCn-live-feedback" v-if="veeErrors.has('descriptionZhCn')">{{ veeErrors.first('descriptionZhCn') }}</small>
            </b-tab>
          </b-tabs>
        </div>

        <div class="d-flex align-items-center justify-content-end">
          <b-button @click="hideCreateModal" class="btn btn-warning rounded-10px px-3 px-lg-5 text-white mr-2 me-lg-4">取消</b-button>
          <b-button :disabled="!hasVeeErrors || isSubmitting" type="submit" variant="primary" class="rounded-10px px-3 px-lg-5">
            <b-spinner v-if="isSubmitting" small class="mr-2"></b-spinner
              >儲存</b-button>
        </div>
      </b-form>
    </b-modal>

    <!-- Edit Feed Modal -->
    <b-modal ref="editFeedModal" centered size="lg" no-close-on-backdrop hide-header hide-footer>
      <b-alert show variant="danger" v-if="veeErrors.all().length">
        <ul class="list-unstyled mb-0">
          <li v-for="error in filterVeeErrors(veeErrors.all())" :key="error">{{ error }}</li>
        </ul>
      </b-alert>

      <b-form @submit.stop.prevent="onSubmitUpdateFeed" class="p-3 p-lg-5">
        <h5 class="text-brown">珠寶資訊</h5>

        <div class="mb-5">
          <b-row class="mb-3">
            <b-col sm="6" lg="4" class="order-2 order-lg-1">
              <div style="position: relative; background: #ababab; height: 200px; width: 200px; display: flex; align-items: center; justify-content: center; overflow: hidden;">
                <b-carousel
                  class="preview-image d-flex align-items-center justify-content-center text-brown"
                  id="carousel-1"
                  controls
                  indicators
                  :interval="4000"
                  background="#ababab"
                  img-width="200"
                  img-height="200"
                  @sliding-start="updateIndex"
                >
                  <div
                    v-if="!previewEditImage.length"
                    class="d-flex align-items-center justify-content-center"
                  >
                    預覽產品相片
                  </div>
                  <b-carousel-slide
                    v-for="(image, index) in previewEditImage"
                    :key="index"
                  >
                    <template v-slot:img>
                      <div
                        class="d-flex justify-content-center align-items-center"
                        style="height: 100%; width: 100%"
                      >
                        <img
                          :src="image"
                          class="img-fluid"
                          style="object-fit: cover; width: 100%; height: 100%"
                        />
                      </div>
                    </template>
                    <div
                      v-if="!!previewEditImage.length"
                      class="d-flex align-items-center justify-content-center pt-4"
                    >
                      {{ currentIndex + 1 }} /{{ previewEditImage.length }}
                    </div>
                  </b-carousel-slide>
                </b-carousel>
              </div>
            </b-col>
            <b-col sm="6" lg="6" class="order-3 order-lg-2">
              <div class="d-flex">
                <b-form-file
                  id="previewEditImage"
                  class="d-none"
                  ref="fileEditImage"
                  name="image"
                  v-validate="{ required: false, ext:'jpeg|jpg|png', size:1024 }"
                  :state="validateState('image')"
                  aria-describedby="input-image-live-feedback"
                  data-vv-as="image"
                  @input="browseEditImage"
                  multiple
                ></b-form-file>

                <div class="mb-3">
                  <label
                    :class="{
                      disabledLabel:
                        previewEditImage.length >= 10 && !!previewEditImage,
                    }"
                    for="previewEditImage"
                    class="bg-white text-brown border border-brown--light rounded-6px py-2 px-4"
                    >上傳產品相片</label
                  >
                </div>
                <div v-if="previewEditImage.length" class="ml-4">
                  <b-button v-b-modal.modal-1>查看全部</b-button>
                  <b-modal size="lg" id="modal-1" title="預覽產品相片">
                    <div class="d-flex flex-wrap">
                      <div
                        class="mx-2 my-4"
                        style="width: 175px; height: 160px"
                        v-for="(image, index) in previewEditImage"
                        :key="index"
                      >
                        <div
                          class="border overflow-hidden rounded"
                          style="width: 100%; height: 100%"
                        >
                          <img
                            class=""
                            :src="image"
                            alt="Preview"
                            style="width: 100%; height: 100%; object-fit: cover"
                          />
                        </div>
                        <img
                          @click="removeCreatedImage(index)"
                          style="margin-top: -60px; cursor: pointer"
                          src="../assets/svg/delete.svg"
                          width="32"
                          height="32"
                        />
                      </div>
                    </div>
                    <template v-slot:modal-footer
                      ><b-button block @click="$bvModal.hide('modal-1')"
                        >關閉</b-button
                      ></template
                    >
                  </b-modal>
                </div>
              </div>
              <div>
                <div class="mb-1">只接受 jpg,jpeg,png格式</div>
                <div class="mb-1">建議尺寸 1,200 x 630 px</div>
                <div class="mb-1">最大上傳檔案 1Mb</div>
              </div>
            </b-col>

            <b-col lg="2" class="order-1 order-lg-3 mb-2 mt-3 mt-lg-4">
              <div class="float-lg-right">
                <div class="d-flex align-items-center">
                  <div class="mr-2">啟用</div>
                  <b-form-checkbox
                    name="check-button"
                    class="custom-switch--success"
                    switch
                    v-model="formControl.active"
                    :checked="formControl.active"
                    ></b-form-checkbox>
                </div>
              </div>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col sm="4">
              <label for="titleZhHk" class="text-brown">珠寶資訊標題 <span class="text-danger">*</span></label>
            </b-col>
            <b-col sm="8" lg="6">
              <b-form-input
                id="titleZhHk"
                class="rounded-0"
                name="titleZhHk"
                v-model="formControl.titleZhHk"
                v-validate="{ required: true, max: 100 }"
                :state="validateState('titleZhHk')"
                aria-describedby="input-titleZhHk-live-feedback"
                data-vv-as="titleZhHk"
              ></b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col sm="4">
              <label for="titleZhCn" class="text-brown">珠宝资讯标题 (简体版本)</label>
            </b-col>
            <b-col sm="8" lg="6">
              <b-form-input
                id="titleZhCn"
                class="rounded-0"
                name="titleZhCn"
                v-model="formControl.titleZhCn"
                v-validate="{ required: false, max: 100 }"
                :state="validateState('titleZhCn')"
                aria-describedby="input-titleZhCn-live-feedback"
                data-vv-as="titleZhCn"
              ></b-form-input>
            </b-col>
          </b-row>

          <b-tabs class="tabs--form mb-4">
            <b-tab>
              <template #title>
                <div class="nav-item--text">珠寶資訊描述<span class="text-danger ml-2">*</span></div>
              </template>
              <vue-editor
                id="descriptionZhHk"
                :editorToolbar="descriptionToolbar"
                name="descriptionZhHk"
                v-model="formControl.descriptionZhHk"
                v-validate="{ required: true, max: 2000 }"
                :state="validateState('descriptionZhHk')"
                data-vv-as="descriptionZhHk"
                placeholder="這款閃閃發光的光環鑲托飾有美麗的扇形密釘鑽石，環繞著中央寶石並裝飾著戒環。鑲有鑽石的畫廊使這枚戒指真正壯觀。"
              ></vue-editor>
              <small class="d-block text-danger" id="input-descriptionZhHk-live-feedback" v-if="veeErrors.has('descriptionZhHk')">{{ veeErrors.first('descriptionZhHk') }}</small>
            </b-tab>

            <b-tab>
              <template #title>
                <div class="nav-item--text">珠宝资讯描述 (简体版本)</div>
              </template>
              <vue-editor
                id="descriptionZhCn"
                :editorToolbar="descriptionToolbar"
                name="descriptionZhCn"
                v-model="formControl.descriptionZhCn"
                v-validate="{ required: false, max: 2000 }"
                :state="validateState('descriptionZhCn')"
                data-vv-as="descriptionZhCn"
                placeholder="這款閃閃發光的光環鑲托飾有美麗的扇形密釘鑽石，環繞著中央寶石並裝飾著戒環。鑲有鑽石的畫廊使這枚戒指真正壯觀。"
              ></vue-editor>
              <small class="d-block text-danger" id="input-descriptionZhCn-live-feedback" v-if="veeErrors.has('descriptionZhCn')">{{ veeErrors.first('descriptionZhCn') }}</small>
            </b-tab>
          </b-tabs>
        </div>

        <div class="d-flex align-items-center justify-content-end">
          <b-button @click="hideEditModal" class="btn btn-warning rounded-10px px-3 px-lg-5 text-white mr-2 me-lg-4">取消</b-button>
          <b-button :disabled="!hasVeeErrors || isSubmitting" type="submit" variant="primary" class="rounded-10px px-3 px-lg-5">
            <b-spinner v-if="isSubmitting" small class="mr-2"></b-spinner
              >儲存</b-button>
        </div>
      </b-form>
    </b-modal>

  </b-container>
</template>

<script>
import debounce from 'lodash/debounce'
import {
  getFeeds,
  getFeed,
  createFeed,
  updateFeed,
  deactivateFeed,
  activateFeed,
  deleteFeed,
  deleteNewImage
} from '@/services/feed.service'
import { VueEditor } from 'vue2-editor'
import TableData from '@/components/TableData.vue'

export default {
  name: 'Feed',

  components: {
    VueEditor,
    TableData
  },

  data: () => ({
    previewImagesList: [],
    currentIndex: 0,
    deletedImage: [],
    descriptionToolbar: [
      ['bold', 'italic', 'underline', 'blockquote'],
      [
        { indent: '-1' },
        { indent: '+1' }
      ],
      [
        { align: '' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' }
      ],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { list: 'check' }
      ],
      ['link', 'image']
    ],
    filters: {
      name: '',
      start: '',
      end: '',
      size: 10,
      page: 0
    },
    feeds: [],
    feedID: null,
    previewImage: null,
    previewEditImage: [],
    formControl: {
      active: true,
      image: [],
      editImage: [],
      titleZhHk: '',
      titleZhCn: '',
      descriptionZhHk: '',
      descriptionZhCn: '',
      sendNotification: false
    },
    headers: [
      { text: '珠寶資訊編號', value: 'id' },
      { text: '預覽珠寶資訊相片', value: 'imageUrl' },
      { text: '珠寶資訊標題', value: 'titleZhHk' },
      { text: '珠宝资讯标题 (简体版本)', value: 'titleZhCn' },
      { text: '珠宝资讯描述', value: 'description' },
      { text: '珠宝资讯描述 (简体版本)', value: 'descriptionCn' },
      { text: '創建日期', value: 'createDate' },
      { text: '狀態', value: 'status' },
      { text: '操作', value: 'action' }
    ],
    isLoading: false,
    isSubmitting: false
  }),

  mounted () {
    this.getAll()
  },

  computed: {
    hasVeeErrors: ({ veeErrors }) => veeErrors.all().length === 0
  },

  methods: {
    updateIndex (newIndex) {
      this.currentIndex = newIndex
    },
    async getAll () {
      this.isLoading = true
      await getFeeds(this.filters).then(
        res => {
          this.feeds = res.data.data
        },
        err => {
          this.makeToast('danger', '發生錯誤，請稍後再試。', err)
        }
      )
      this.isLoading = false
    },
    search: debounce(function () {
      this.getAll()
    }, 250),
    handlePaginate (pageNo) {
      this.filters.page = pageNo
      getFeeds(this.filters).then(
        res => {
          this.feeds = res.data.data
        },
        err => {
          this.makeToast('danger', '發生錯誤，請稍後再試。', err)
        }
      )
    },
    validateState (ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref)
      }
      return null
    },
    filterVeeErrors (veeErrors = []) {
      return veeErrors.filter((element, index) => {
        return veeErrors.indexOf(element) === index
      })
    },
    async onSubmitCreateFeed () {
      try {
        const result = await this.$validator.validateAll()
        if (!result) {
          return
        }
        this.isSubmitting = true
        const createFormData = new FormData()

        this.formControl.image.map((item, index) => {
          createFormData.append('image', item)
        })

        createFormData.append('active', this.formControl.active)
        createFormData.append('titleZhHk', this.formControl.titleZhHk)
        createFormData.append('titleZhCn', this.formControl.titleZhCn)
        createFormData.append('descriptionZhHk', this.formControl.descriptionZhHk)
        createFormData.append('descriptionZhCn', this.formControl.descriptionZhCn)
        createFormData.append('sendNotification', this.formControl.sendNotification)

        const res = await createFeed(createFormData)
        if (res.data.success) {
          this.hideCreateModal()
          this.isSubmitting = false
          this.getAll()
          this.makeToast('success', '珠寶資訊', '儲存成功')
        }
      } catch (err) {
        this.makeToast('danger', '發生錯誤，請稍後再試。', err)
        this.isSubmitting = false
      }
    },
    async onSubmitUpdateFeed () {
      this.$validator.validateAll().then(async result => {
        if (!result) {
          return
        }
        this.isSubmitting = true

        let addNewPhoto = false
        const updateFormData = new FormData()
        updateFormData.append('active', this.formControl.active)

        this.formControl.editImage.map((item, index) => {
          if (typeof item === 'object') {
            updateFormData.append('image', item)
            addNewPhoto = true
          }
        })

        if (addNewPhoto === false) {
          updateFormData.append('image', new Blob())
        }

        updateFormData.append('titleZhHk', this.formControl.titleZhHk)
        updateFormData.append('titleZhCn', this.formControl.titleZhCn)
        updateFormData.append('descriptionZhHk', this.formControl.descriptionZhHk)
        updateFormData.append('descriptionZhCn', this.formControl.descriptionZhCn)

        const deletingImage = this.deletedImage.map(async (item, index) =>
          await deleteNewImage(this.feedID, item)
        )

        await Promise.all(deletingImage)

        updateFeed(this.feedID, updateFormData).then(
          res => {
            if (res.data.success) {
              this.hideEditModal()
              this.isSubmitting = false
              this.getAll()
              this.makeToast('success', '珠寶資訊', '更新成功')
            }
          },
          err => {
            this.makeToast('danger', '發生錯誤，請稍後再試。', err)
            this.isSubmitting = false
          }
        )
      })
    },
    handleDeactivate (id) {
      deactivateFeed(id).then(
        res => {
          if (res.data.success) {
            this.getAll()
            this.makeToast('success', '珠寶資訊', '停用成功')
          }
        },
        err => {
          this.makeToast('danger', '發生錯誤，請稍後再試。', err)
        }
      )
    },
    handleActivate (id) {
      activateFeed(id).then(
        res => {
          if (res.data.success) {
            this.getAll()
            this.makeToast('success', '珠寶資訊', '啟用成功')
          }
        },
        err => {
          this.makeToast('danger', '發生錯誤，請稍後再試。', err)
        }
      )
    },
    handleDelete (id) {
      deleteFeed(id).then(
        res => {
          if (res.data.success) {
            this.getAll()
            this.makeToast('success', '珠寶資訊', '刪除成功')
          }
        },
        err => {
          this.makeToast('danger', '發生錯誤，請稍後再試。', err)
        }
      )
    },
    makeToast (variant = null, title = 'Title', message = 'Message!') {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 3000,
        variant: variant,
        solid: true
      })
    },

    browseImage () {
      const selectedImageInput = this.$refs.fileImage.files
      if (selectedImageInput.length) {
        for (let i = 0; i < selectedImageInput.length; i++) {
          const maxLength = this.formControl.image?.length + 1
          if (maxLength > 10) return
          const file = selectedImageInput[i]
          const maxSize = 1024 * 1024
          if (file.size <= maxSize) {
            this.formControl.image.push(file)
            const reader = new FileReader()
            reader.onload = (e) => {
              this.previewImagesList.push(e.target.result)
            }
            reader.readAsDataURL(file)
          }
        }
      }

      // reader.readAsDataURL(selectedImageInput)
      // this.$emit('input', selectedImageInput)
      // }
    },
    removeImage (idx) {
      this.formControl.image.splice(idx, 1)
      this.previewImagesList.splice(idx, 1)
    },
    async removeCreatedImage (idx) {
      if (typeof this.formControl.editImage[idx] === 'string') {
        this.deletedImage.push(this.formControl.editImage[idx])
      }
      this.formControl.editImage.splice(idx, 1)
      this.previewEditImage.splice(idx, 1)
    },
    browseEditImage () {
      const selectedImageInput = this.$refs.fileEditImage.files
      if (selectedImageInput.length) {
        for (let i = 0; i < selectedImageInput.length; i++) {
          const maxLength = this.formControl.editImage?.length
          if ((maxLength + this.previewEditImage.length) > 10) return
          const file = selectedImageInput[i]
          const maxSize = 1024 * 1024

          if (file.size <= maxSize) {
            const reader = new FileReader()

            reader.readAsDataURL(file)
            reader.onload = (e) => {
              this.previewEditImage.push(e.target.result)
            }

            this.formControl.editImage.push(file)
          }
        }
      }
    },

    showCreateModal () {
      this.$refs.createFeedModal.show()
    },
    hideCreateModal () {
      this.resetformControl()
      this.$refs.createFeedModal.hide()
    },
    showEditModal (id) {
      getFeed(id).then(
        res => {
          if (res.data.success) {
            this.feedID = id
            const imageURLs = res.data.data.imageUrl

            this.formControl = {
              active: res.data.data.active,
              titleZhHk: res.data.data.titleZhHk,
              titleZhCn: res.data.data.titleZhCn,
              descriptionZhHk: res.data.data.descriptionZhHk,
              descriptionZhCn: res.data.data.descriptionZhCn,
              image: [],
              editImage: []
            }

            this.previewEditImage = []
            imageURLs.map((item, index) => {
              this.previewEditImage.push(item)
              this.formControl.editImage.push(item)
            })
            this.$refs.editFeedModal.show()
          }
        },
        err => {
          this.makeToast('danger', '發生錯誤，請稍後再試。', err)
        }
      )
    },
    hideEditModal () {
      this.resetformControl()
      this.$refs.editFeedModal.hide()
    },

    resetformControl () {
      this.previewImagesList = []
      this.previewImage = null
      this.previewEditImage = []
      this.formControl = {
        active: true,
        image: [],
        editImage: [],
        titleZhHk: '',
        titleZhCn: '',
        descriptionZhHk: '',
        descriptionZhCn: '',
        sendNotification: false
      }
      this.currentIndex = 0
    }
  }
}
</script>

<style scoped lang="scss">
.preview-image {
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center center;
}

@media (min-width: 992px) {
  .preview-image {
    width: 218px;
    height: 160px;
  }
 }
</style>
